<template>
  <div class="dimission" v-loading="loading">
    <div class="dimission__content" v-show="!loading">
      <descriptions :descriptionData="listData" />
    </div>
  </div>
</template>

<script>
import rowItemDes from '@/views/intern/pages/components/rowItemDes'
import mixinsGet from '@/views/intern/pages/mixinsGet'
import { getInternInfoTab } from '@/api/intern'
import descriptions from '@/components/descriptions/descriptions'
import { mergeDesDataOfPort } from '@/utils/util'
export default {
  name: 'dimission',
  mixins: [mixinsGet],
  components: {
    rowItemDes,
    descriptions
  },
  created() {
    this.loading = true
    let id = this.$route.query.id
    if (id) {
      getInternInfoTab({
        id,
        queryType: 'Dimission'
      }).then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            mergeDesDataOfPort(this.listData, res.data)
            setTimeout(() => {
              this.loading = false
            }, 500)
          }
        }
      })
    }
  },
  data() {
    return {
      loading: false,
      listData: [
        {
          title: 'Dimission Info',
          column: 4,
          content: [
            {
              label: 'Last Working Date',
              key: 'lastWorkingDate',
              value: '',
              lg: 6
            },
            {
              label: 'Termination Notice Date',
              key: 'terminationNoticeDate',
              value: '',
              lg: 6
            },
            { label: 'Reason', key: 'reason', value: '', lg: 6 },
            { label: 'Comments', key: 'comments', value: '', lg: 6 }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.dimission {
  height: 100%;
  &__content {
    height: 100%;
  }
}
</style>
