<template>
  <div class="rowItemDes">
    <el-row
      class="rowItemDes-row"
      v-for="(rowItem, index) in listData"
      :key="index"
    >
      <RowTitle :title="rowItem.title">
        <el-row
          class="rowItemDes-row-title-content-item"
          v-for="(rowItemChild, index) in rowItem.content"
          :key="index"
          :gutter="100"
        >
          <el-col
            class="rowItemDes-row-title-content-col"
            v-for="(colItem, index) in rowItemChild"
            :key="index"
            :lg="colItem.lg"
          >
            <div class="rowItemDes-row-title-content-col-content">
              <span class="label">{{ colItem.label }}:</span>
              <span class="value">{{ colItem.value }}</span>
            </div>
          </el-col>
        </el-row>
      </RowTitle>
    </el-row>
  </div>
</template>

<script>
import RowTitle from '@/views/intern/pages/components/RowTitle'
export default {
  name: 'rowItemDes',
  components: {
    RowTitle
  },
  props: {
    listData: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.rowItemDes {
  .rowItemDes-row {
    .rowItemDes-row-title-content-item {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      .rowItemDes-row-title-content-col {
        .rowItemDes-row-title-content-col-content {
          width: 80%;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 700;
          padding-bottom: 5px;
          .label {
            font-size: 14px;
            font-family: ArialMT;
            color: #868686;
          }
          .value {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #323435;
          }
        }
      }
    }
  }
}
</style>
