<template>
  <div class="FileUpload">
    <el-upload
      ref="uploadRef"
      class="upload-demo"
      action="#"
      :auto-upload="false"
      :show-file-list="false"
      :onChange="onChange"
      :disabled="disabled"
    >
      <template #trigger>
        <img src="../../../../assets/img/upload.png" alt="" />
        <div class="title_top">点击此区域上传附件或将文件拖至此处</div>
      </template>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'FileUpload',
  props: {
    uploadType: {
      default: 'img'
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    onChange(val) {
      this.$emit('onChange', val)
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.FileUpload {
  width: 100%;
  height: 100%;
  .upload-icon {
    width: 100px;
    height: 100px;
    background: rgb(250, 250, 250);
    color: rgb(184, 184, 184);
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    line-height: 100px;
    border: 2px dashed rgb(184, 184, 184);
  }
  .upload-demo {
    img {
      width: 35px;
      height: 35px;
      margin-top: 15px;
    }
    :deep(.el-upload) {
      width: 100%;
      height: 92px;
      border-style: dashed;
      border-radius: 3px;
      //padding: 15px 0 0 0;
      border-color: rgba(0, 0, 0, 0.3);
      background-color: rgba(249, 249, 249);
    }
    .title_top {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: darkgray;
    }
    .title_bottom {
      font-size: 0.9em;
    }
  }
}
</style>
