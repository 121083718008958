<template>
  <div class="Position" v-loading="loading">
    <div class="Position__content" v-show="!loading">
      <descriptions :descriptionData="listData" />
      <rowTitle title="History">
        <positionTable
          :column="column"
          :table-list="tableList"
          @getApi="queryApi"
        />
      </rowTitle>
    </div>
  </div>
</template>

<script>
import rowItemDes from '@/views/intern/pages/components/rowItemDes'
import positionTable from '@/views/intern/pages/components/positionTable'
import rowTitle from '@/views/intern/pages/components/RowTitle'
import mixinsGet from '@/views/intern/pages/mixinsGet'
import descriptions from '@/components/descriptions/descriptions'
import { getInternInfoTab } from '@/api/intern'
import { mergeDesDataOfPort } from '@/utils/util'
export default {
  name: 'Position',
  mixins: [mixinsGet],
  components: {
    rowItemDes,
    positionTable,
    rowTitle,
    descriptions
  },
  created() {
    this.queryApi()
  },
  methods: {
    queryApi() {
      this.loading = true
      let id = this.$route.query.id
      if (id) {
        getInternInfoTab({
          id,
          queryType: 'Position'
        }).then((res) => {
          if (res && res.code === 200) {
            if (res.data) {
              mergeDesDataOfPort(this.listData, res.data)
              this.tableList = res.data.history
              setTimeout(() => {
                this.loading = false
              }, 500)
            }
          }
        })
      }
    }
  },
  data() {
    return {
      loading: false,
      listData: [
        {
          title: 'Current Position',
          column: 4,
          content: [
            {
              label: 'Internal Brand Name',
              key: 'internalBrandName',
              value: '',
              lg: 8
            },
            {
              label: 'Business Unit Name',
              key: 'businessUnitName',
              value: '',
              lg: 8
            },
            {
              label: 'Department/Team',
              key: 'departmentTeam',
              value: '',
              lg: 8
            },
            {
              label: 'Business Title In English',
              key: 'businessTitleInEnglish',
              value: '',
              lg: 8
            },
            {
              label: 'Job Grade',
              key: 'jobGrade',
              value: '',
              lg: 8
            },
            {
              label: 'Parent Function',
              key: 'parentFunction',
              value: '',
              lg: 8
            },
            {
              label: 'Line Manager LIID',
              key: 'lineManagerLIID',
              value: '',
              lg: 8
            },
            {
              label: 'Child Function',
              key: 'childFunction',
              value: '',
              lg: 8
            },
            {
              label: 'Line Manager Name',
              key: 'lineManagerName',
              value: '',
              lg: 8
            },
            {
              label: 'Reason For Position',
              key: 'reasonForPosition',
              value: '',
              lg: 8
            },
            {
              label: 'Line Manager Email',
              key: 'lineManagerEmail',
              value: '',
              lg: 8
            },
            {
              label: 'Standard Weekly Hours',
              key: 'standardWeeklyHours',
              value: '',
              lg: 8
            },
            {
              label: 'Employee type',
              key: 'employeeType',
              value: '',
              lg: 8
            },
            {
              label: 'CEO',
              key: 'ceoName',
              value: '',
              lg: 8
            }
          ]
        }
      ],
      tableList: [],

      column: [
        {
          key: 'brand',
          name: 'Brand',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'businessUnitId',
          name: 'Business Unit',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'jobGrade',
          name: 'Job Grade',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'parentFunction',
          name: 'Parent Function',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'childFunction',
          name: 'Child Function',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'lineManagerLlid',
          name: 'Lion ID of Line Manager',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 160
        },
        {
          key: 'updateTime',
          name: 'Update Time',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'updateUser',
          name: 'Update User',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.Position {
  height: 100%;
  &__content {
    height: 100%;
  }
}
</style>
