<template>
  <div class="Attachments" v-loading="loading">
    <div class="Attachments__content" v-show="!loading">
      <div class="content-top">
        <div class="bankCard">
          <div class="title">银行卡: &nbsp;&nbsp;</div>
          <pgUploadFile
            :id="id"
            type="InternBankCard"
            :upload-list-call="uploadList.InternBankCardList"
          />
        </div>
        <div class="bankCard">
          <div class="title">身份证正面:</div>
          <pgUploadFile
            :id="id"
            type="InternFrontIdCard"
            :upload-list-call="uploadList.InternFrontIdCardList"
          />
        </div>
        <div class="bankCard">
          <div class="title">身份证反面:</div>
          <pgUploadFile
            :id="id"
            type="InternBackIdCard"
            :upload-list-call="uploadList.InternBackIdCardList"
          />
        </div>
        <div class="bankCard">
          <div class="title">学生证:</div>
          <pgUploadFile
            :id="id"
            type="InternStudentIdCard"
            :upload-list-call="uploadList.InternStudentIdCardList"
          />
        </div>
      </div>
      <div class="content-bottom">
        <div class="bankCard">
          <div class="title">简历: &nbsp;&nbsp;</div>
          <pgUploadFile
            :id="id"
            type="InternResume"
            :upload-list-call="uploadList.InternResumeChangeList"
          />
        </div>
        <div class="bankCard">
          <div class="title">实习协议: &nbsp;&nbsp;</div>
          <pgUploadFile
            :id="id"
            type="InternshipAgreement"
            :upload-list-call="uploadList.InternshipAgreementList"
          />
        </div>
        <div class="bankCard">
          <div class="title">承诺函: &nbsp;&nbsp;</div>
          <pgUploadFile
            :id="id"
            type="InternAcknowledgement"
            :upload-list-call="uploadList.InternAcknowledgementList"
          />
        </div>
        <div class="bankCard">
          <div class="title">保密协议: &nbsp;&nbsp;</div>
          <pgUploadFile
            :id="id"
            type="InternConfidentialityAgreement"
            :upload-list-call="uploadList.InternConfidentialityAgreementList"
          />
        </div>
      </div>
      <div class="content-bottom">
        <div class="bankCard">
          <div class="title">社交媒体协议: &nbsp;&nbsp;</div>
          <pgUploadFile
            :id="id"
            type="SocialMediaAgreement"
            :upload-list-call="uploadList.SocialMediaAgreementList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/views/intern/pages/components/FileUpload'
import { getInternInfoTab } from '@/api/intern'
import pgUploadFile from '@/components/pg-uploadFile/pg-uploadFile'
export default {
  name: 'attachments',
  components: {
    FileUpload,
    pgUploadFile
  },
  created() {
    this.queryApi()
  },
  data() {
    return {
      uploadList: {
        InternBankCardList: [],
        InternFrontIdCardList: [],
        InternBackIdCardList: [],
        InternStudentIdCardList: [],
        InternResumeChangeList: [],
        InternshipAgreementList: [],
        InternAcknowledgementList: [],
        InternConfidentialityAgreementList: [],
        SocialMediaAgreementList: []
      },
      changeApiName: {
        InternBackIdCardList: 'backIdCardList',
        InternBankCardList: 'bankCardList',
        InternConfidentialityAgreementList: 'confidentialityAgreementList',
        InternFrontIdCardList: 'frontIdCardList',
        InternAcknowledgementList: 'internAcknowledgementList',
        InternshipAgreementList: 'internshipAgreementList',
        InternResumeChangeList: 'resumeList',
        SocialMediaAgreementList: 'socialMediaAgreementList',
        InternStudentIdCardList: 'studentIdCardList'
      },
      loading: false,
      id: ''
    }
  },
  methods: {
    queryApi() {
      this.loading = true
      let id = this.$route.query.id
      if (id) {
        this.id = id
        getInternInfoTab({
          id,
          queryType: 'Attachments'
        }).then((res) => {
          if (res && res.code === 200) {
            if (res.data) {
              Object.keys(this.uploadList).forEach((item) => {
                this.uploadList[item] = res.data[this.changeApiName[item]]
              })
              setTimeout(() => {
                this.loading = false
              }, 500)
            }
          }
        })
      }
    },
    getData() {
      return this.uploadList
    }
  }
}
</script>

<style lang="scss" scoped>
.Attachments {
  background: white;
  margin-top: 20px;
  height: 100%;
  overflow-x: auto;

  &__title {
    height: 100%;
    font-size: 1.5em;
    font-weight: 700;
    span {
      background: rgb(182, 154, 110);
      color: white;
    }
  }
  &__content {
    .content-top,
    .content-bottom {
      display: flex;
      box-sizing: border-box;
    }
    .bankCard {
      //display: flex;
      margin-bottom: 30px;
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #323435;
        padding-bottom: 12px;
      }
    }
    :deep(.PG-uploadFile) {
      .FileUpload {
        width: 350px;
      }
      .bankCardList {
        width: 350px;
        .bankCardList-item {
          width: 350px;
        }
      }
    }
  }
}
</style>
