<template>
  <div class="basicInfo" v-loading="loading">
    <descriptions v-show="!loading" :descriptionData="listData" />
  </div>
</template>

<script>
import rowItemDes from '@/views/intern/pages/components/rowItemDes'
import { getInternInfoTab } from '@/api/intern'
import mixinsGet from '@/views/intern/pages/mixinsGet'
import descriptions from '@/components/descriptions/descriptions'
import { mergeDesDataOfPort } from '@/utils/util'
export default {
  name: 'basicInfo',
  mixins: [mixinsGet],
  components: {
    rowItemDes,
    descriptions
  },
  created() {
    this.loading = true
    let id = this.$route.query.id
    if (id) {
      getInternInfoTab({
        id,
        queryType: 'BasicInfo'
      }).then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            mergeDesDataOfPort(this.listData, res.data)
            setTimeout(() => {
              this.loading = false
            }, 500)
          }
        }
      })
    }
  },
  methods: {},
  data() {
    return {
      loading: false,
      listData: [
        {
          title: 'Basic info',
          column: 4,
          content: [
            { label: 'Local Name', key: 'localName', value: '', lg: 8 },
            { label: 'English Name', key: 'englishName', value: '', lg: 8 },
            { label: 'Gender', key: 'gender', value: '', lg: 8 },
            { label: 'Age', key: 'age', value: '', lg: 8 },
            { label: 'Ethnicity', key: 'ethnicity', value: '', lg: 8 },
            { label: 'Country', key: 'country', value: '', lg: 8 },
            { label: 'Birthday', key: 'birthday', value: '', lg: 8 },
            {
              label: 'ID/Passport No',
              key: 'idPassportNo',
              value: '',
              lg: 8
            },
            { label: 'Expat/Local', key: 'expatLocal', value: '', lg: 8 },
            { label: 'HFM Code', key: 'hfmCode', value: '', lg: 8 },
            {
              label: 'Join Date',
              key: 'joinDate',
              value: '',
              lg: 8
            },
            {
              label: 'Legal Entity In Chinese',
              key: 'legalEntityInChinese',
              value: '',
              lg: 8
            },
            {
              label: 'HRBP',
              key: 'hrbp',
              value: '',
              lg: 8
            },
            {
              label: 'Email Account',
              key: 'emailAccount',
              value: '',
              lg: 8
            },
            {
              label: 'Legal Entity In English',
              key: 'legalEntityInEnglish',
              value: '',
              lg: 8
            },
            {
              label: 'Career Setting ID',
              key: 'careerSettingId',
              value: '',
              lg: 8
            },
            {
              label: 'Employee LIID',
              key: 'employeeLiid',
              value: '',
              lg: 8
            },
            {
              label: 'Business Unit Name',
              key: 'businessUnitName',
              value: '',
              lg: 8
            }
          ]
        },
        {
          title: 'Nation & Region',
          column: 4,
          content: [
            {
              label: '籍贯/出生地',
              key: 'nativePlaceOne',
              value: '',
              lg: 8
            },
            {
              label: '户口性质',
              key: 'householdRegister',
              value: '',
              lg: 8
            },
            {
              label: '本市住址',
              key: 'permanentAddress',
              value: '',
              lg: 8
            },
            {
              label: '户口地址',
              key: 'nativePlaceTwo',
              value: '',
              lg: 8
            }
          ]
        },
        {
          title: 'Contract Info',
          column: 4,
          content: [
            {
              label: 'Mobile Number',
              key: 'mobileNumber',
              value: '',
              lg: 8
            },
            {
              label: 'Personal Mail Address',
              key: 'personalMailAddress',
              value: '',
              lg: 8
            },
            {
              label: 'Business Email',
              key: 'businessEmail',
              value: '',
              lg: 8
            }
          ]
        },
        {
          title: 'Emergency Contact Info',
          column: 4,
          content: [
            {
              label: 'Emergency Contact',
              key: 'emergencyContact',
              value: '',
              lg: 8
            },
            {
              label: 'Emergency Contact Phone',
              key: 'emergencyContactPhone',
              value: '',
              lg: 8
            },
            {
              label: 'Emergency Contact Relation',
              key: 'emergencyContactRelation',
              value: '',
              lg: 8
            }
          ]
        },
        {
          title: 'Finance Info',
          column: 4,
          content: [
            {
              label: 'Bank Name',
              key: 'bankName',
              value: '',
              lg: 8
            },
            {
              label: 'Account Number',
              key: 'accountNumber',
              value: '',
              lg: 8
            },
            {
              label: 'Account Name',
              key: 'accountName',
              value: '',
              lg: 8
            }
          ]
        },
        {
          title: 'Floor',
          column: 4,
          content: [
            {
              label: 'Floor',
              key: 'floor',
              value: '',
              lg: 8
            }
          ]
        },
        {
          title: 'Others',
          column: 4,
          content: [
            {
              label:
                'Have you ever been convicted of a criminal offense? If so, please give detail.',
              key: 'othersOne',
              value: '',
              lg: 24
            },

            {
              label:
                'I voluntarily disclose below information to help the Company to assess my application.',
              key: 'othersTwo',
              value: '',
              lg: 24
            },

            {
              label: 'Remark',
              key: 'remark',
              value: '',
              lg: 24
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.basicInfo {
  height: 100%;
}
</style>
