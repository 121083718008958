<template>
  <div class="PTable">
    <PocTable v-loading="loading" :data="tableData" :total="total" hidepage>
      <el-table-column
        v-for="item in columnConfig"
        v-if="item.visible"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :sortable="item.sortable"
        :width="item.width"
      ></el-table-column>

      <!--      <el-table-column label="Action" width="80" fixed="right" align="center">-->
      <!--        <template slot-scope="scope">-->
      <!--          <svg-icon-->
      <!--            v-if="scope.$index === 0"-->
      <!--            icon="edit"-->
      <!--            @click.native="onEdit(scope.row, scope.$index)"-->
      <!--          />-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </PocTable>
    <baseInfoDialog ref="baseInfoDialogRef" @onConfirm="onConfirm" />
  </div>
</template>

<script>
import baseInfoDialog from '@/views/intern/pages/components/baseInfoDialog'
import { saveContractHistory, savePositionHistory } from '@/api/intern'
export default {
  name: 'PTable',
  components: {
    baseInfoDialog
  },

  props: {
    column: {
      required: true
    },
    tableList: {
      required: true
    }
  },
  watch: {
    column: {
      handler(val) {
        this.columnConfig = val
      },
      deep: true,
      immediate: true
    },
    tableList: {
      handler(val) {
        this.tableData = val
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      loading: false,
      tableData: [],
      columnConfig: [],
      total: 0,
      title: 'title',
      dialogVisible: false,
      formInline: {
        hfmCode: '',
        legalEntity: '',
        workLocation: '',
        officeLocation: '',
        employmentType: '',
        contractEndDate: '',
        updateUser: ''
      },
      HFMList: [],
      legalEntityList: [],
      workLocationList: [],
      officeLocationList: [],
      editIndex: ''
    }
  },
  methods: {
    onEdit(row, index) {
      this.editIndex = index
      this.$refs.baseInfoDialogRef.show({
        content: row
      })
    },
    onConfirm(val) {
      const data = {
        ...val,
        id: this.$route.query.id
      }
      saveContractHistory(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('修改成功')
          this.$emit('getApi')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.PTable {
}
</style>
