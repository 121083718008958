<template>
  <div class="internDetails">
    <div class="internDetails-title">
      <div class="topTitle">
        <div>实习生管理 <span> / 详情</span></div>
        <div>
          <el-button size="mini" @click="back">返回</el-button>
        </div>
      </div>
      <div class="topRight">
        <div class="internDetails-title-left">
          <div class="el-avatar">
            <img
              src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
              alt=""
            />
          </div>
          <div>
            <div class="internDetails-title-person">
              <span class="internDetails-title-person-title">{{
                titleObj.englishName || '-'
              }}</span>
              &nbsp;
              <span class="internDetails-title-person-content">{{
                titleObj.localName || '-'
              }}</span>
            </div>
            <div class="internDetails-title-data">
              <div>
                <span class="internDetails-title-data-title"
                  >Internal Brand Name:</span
                >
                <span class="internDetails-title-data-content">{{
                  titleObj.internalBrandName
                }}</span>
              </div>
              <div>
                <span class="internDetails-title-data-title"
                  >Line Manager:
                </span>
                <span class="internDetails-title-data-content">{{
                  titleObj.lineManager
                }}</span>
              </div>
              <div>
                <span class="internDetails-title-data-title">Join Date:</span>
                <span class="internDetails-title-data-content">{{
                  titleObj.joinDate
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="internDetails-title-right">
          <div class="internDetails-title-right-icon">
            <div class="icon-list-item">
              <div class="iconItem">
                <svg-icon icon="internshipAgreement" />
              </div>
              <div class="icon-list-item-font" @click="InternAgreement">
                实习生协议
              </div>
            </div>
            <div class="icon-list-item">
              <div class="iconItem">
                <svg-icon icon="certificate" style="font-size: 25px" />
              </div>
              <div class="icon-list-item-font" @click="PracticeProvesThat">
                实习证明
              </div>
            </div>
            <div class="icon-list-item">
              <div class="iconItem">
                <svg-icon icon="downloadAttachment" />
              </div>
              <div class="icon-list-item-font" @click="DownloadTheAttachment">
                附件下载
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="internDetails-content">
      <el-tabs v-model="activeName">
        <el-tab-pane label="Basic info" name="basicInfo" />
        <el-tab-pane label="Position" name="position" />
        <el-tab-pane label="Contract" name="contract" />
        <el-tab-pane label="Education" name="education" />
        <el-tab-pane label="Dimission" name="dimission" />
        <el-tab-pane label="Attachments" name="attachments" />
      </el-tabs>
      <div class="internDetails-content-item">
        <component :is="activeName" />
      </div>
    </div>
  </div>
</template>
<script>
import basicInfo from '@/views/intern/pages/components/basicInfo'
import Position from '@/views/intern/pages/components/Position'
import Contract from '@/views/intern/pages/components/Contract'
import Education from '@/views/intern/pages/components/Education'
import dimission from '@/views/intern/pages/components/dimission'
import attachments from '@/views/intern/pages/components/attachments'
import {
  downloadAttach,
  getInternInfo,
  internshipAgreement,
  internshipCertificate,
  downloadInternshipCertificate
} from '@/api/intern'
export default {
  name: 'internDetails',
  components: {
    basicInfo,
    position: Position,
    contract: Contract,
    education: Education,
    dimission,
    attachments
  },
  created() {
    let id = this.$route.query.id
    if (id) {
      this.id = id
      this.queryApi()
    } else {
      this.back()
    }
  },

  data() {
    return {
      titleObj: {
        localName: '',
        englishName: '',
        internalBrandName: '',
        lineManager: '',
        joinDate: ''
      },
      activeName: 'basicInfo',
      id: ''
    }
  },
  methods: {
    queryApi() {
      getInternInfo({ id: this.id }).then((res) => {
        if (res && res.code === 200) {
          Object.keys(this.titleObj).forEach((item) => {
            this.titleObj[item] = res.data[item]
          })
        }
      })
    },
    back() {
      this.$router.push({
        path: '/intern'
      })
    },
    PracticeProvesThat() {
      downloadInternshipCertificate({ internId: this.id })
      // .then((res) => {
      //   if (res && res.code === 200) {
      //     window.open(res.data)
      //   }
      // })
    },
    InternAgreement() {
      internshipAgreement({ id: this.id }).then((res) => {
        if (res && res.code === 200) {
          window.open(res.data)
        }
      })
    },
    DownloadTheAttachment() {
      downloadAttach({ ids: this.id }).then((res) => {
        if (res && res.code === 200) {
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.internDetails {
  position: relative;
  .Intertitle {
    height: 40px;
    background: white;
    margin-top: -13px;
    margin-bottom: 10px;
    margin-left: -15px;
    span {
      font-size: 16px;
      font-weight: bold;
      display: block;
      padding-top: 10px;
      padding-left: 15px;
    }
  }
  .internDetails-title {
    overflow: hidden;
    height: 166px;
    box-sizing: border-box;
    padding: 17px 20px 20px 20px;
    background: #ffffff;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    .internDetails-title-left {
      display: flex;
      margin-right: 19px;
      .el-avatar {
        width: 79px;
        height: 80px;
        margin-right: 24px;
        border-radius: 50%;
        img {
          width: 100% !important;
          height: 100% !important;
        }
      }
      .internDetails-title-person {
        font-size: 24px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #303133;
        margin-bottom: 20px;
      }
      .internDetails-title-data {
        display: flex;
        font-size: 14px;
        color: #7a878e;
        margin-right: 49px;
        div {
          .internDetails-title-data-title {
            margin-right: 12px;
          }
          .internDetails-title-data-content {
            font-size: 16px;
            font-family: ArialMT;
            color: #323435;
          }
          margin-right: 24px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .internDetails-title-right {
      display: flex;
      .internDetails-title-right-icon {
        display: flex;
        //margin-top: 20px;
        //margin-right: 50px;
        .icon-list-item {
          margin-right: 49px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          .iconItem {
            text-align: center;
            font-size: 30px;
            margin-top: 6px;
            svg g polyline {
              stroke: $--color-button-primary;
            }
            &:hover {
            }
          }
          .icon-list-item-font {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303133;
            margin-top: 8px;
            &:hover {
              color: $--color-button-primary;
              text-decoration: underline;
            }
          }
        }
      }
      .internDetails-title-right-back {
        font-size: 30px;
        margin-top: 30px;
        margin-right: 30px;
        cursor: pointer;
        &:hover {
          color: dodgerblue;
        }
      }
    }
  }
  .internDetails-content {
    background: #ffffff;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 15px 0 0 0;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    padding: 15px;
    .internDetails-content-item {
      //height: calc(100vh - 60px - 260px);
      margin-top: 5px;
    }
  }

  .topTitle {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    color: #909399;
    span {
      font-size: 21px;
      font-weight: 500;
      color: #303133;
    }
    margin-bottom: 20px;
  }
  .topRight {
    display: flex;
  }
}
</style>
