<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="添加学历"
    width="400px"
    append-to-body
    destroy-on-close
    :before-close="close"
  >
    <el-form
      ref="educationaleducationDialogRef"
      :model="ruleForm"
      :rules="rules"
      label-width="80px"
      size="mini"
      class="demo-ruleForm"
    >
      <el-form-item label="学历" prop="education">
        <el-select v-model="ruleForm.education" placeholder="请选择">
          <el-option
            v-for="item in educationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="修业状况" prop="degreeReceived">
        <el-radio-group v-model="ruleForm.degreeReceived">
          <el-radio label="Certificate of Completion"
            >结业 Certificate of Completion</el-radio
          >
          <el-radio label="Certificate of Incompletion"
            >肄业 Certificate of Incompletion</el-radio
          >
          <el-radio label="Pursuing Study">在读 Pursuing Study</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="学校名称" prop="nameOfSchool">
        <el-input v-model="ruleForm.nameOfSchool"></el-input>
      </el-form-item>
      <el-form-item label="专业" prop="majorSubject">
        <el-input v-model="ruleForm.majorSubject"></el-input>
      </el-form-item>
      <el-form-item label="起" prop="dateFrom">
        <el-date-picker
          v-model="ruleForm.dateFrom"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Pick a day"
        />
      </el-form-item>
      <el-form-item label="迄" prop="dateTo">
        <el-date-picker
          v-model="ruleForm.dateTo"
          type="date"
          placeholder="Pick a day"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-checkbox
        v-model="ruleForm.correspondingDegreeObtained"
        true-label="Yes"
        false-label="No"
        label="取得相应学位"
      />
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">Cancel</el-button>
        <el-button type="primary" @click="confirm">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        education: '',
        degreeReceived: '',
        nameOfSchool: '',
        majorSubject: '',
        dateFrom: '',
        dateTo: '',
        id: '',
        correspondingDegreeObtained: 'No'
      },
      dialogVisible: false,
      /*
            <el-radio label="结业">结业 Certificate of Completion</el-radio>
              <el-radio label="肄业">肄业 Certificate of Incompletion</el-radio>
              <el-radio label="在读">在读 Pursuing Study</el-radio>
       */

      educationList: [
        { label: '博士', value: 'PhD' },
        { label: '硕士', value: 'Master' },
        { label: '学士', value: 'Bachelor' },
        { label: '其他', value: 'Others' }
      ],
      statusVal: 'add',
      rules: {}
    }
  },
  created() {
    Object.keys(this.ruleForm).forEach((item) => {
      this.rules[item] = { required: true, trigger: 'change' }
    })
  },
  methods: {
    show(val) {
      this.dialogVisible = true
      if (val) {
        console.log(val)
        if (val.status && val.status === 'edit') {
          this.statusVal = val.status
          Object.keys(this.ruleForm).forEach((item) => {
            this.ruleForm[item] = val.content[item]
          })
        }
      }
    },
    close() {
      this.ruleForm = {
        education: '',
        degreeReceived: '',
        nameOfSchool: '',
        majorSubject: '',
        dateFrom: '',
        dateTo: '',
        id: '',
        correspondingDegreeObtained: 'No'
      }
      this.dialogVisible = false
    },
    async confirm() {
      let validate = false
      await this.$refs.educationaleducationDialogRef
        .validate()
        .then((success) => {
          validate = !!success
        })
      if (validate) {
        this.$emit('onConfirm', {
          status: this.statusVal,
          content: this.ruleForm
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
</style>
