export default {
  methods: {
    mergeDetails(target, obj) {
      obj.forEach((item) => {
        item.content.forEach((item1) => {
          item1.forEach((item2) => {
            item2.value = target[item2.prop]
          })
        })
      })
    }
  }
}
