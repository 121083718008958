<template>
  <div class="Contract" v-loading="loading">
    <div class="Contract__content" v-show="!loading">
      <descriptions :descriptionData="listData" />
      <RowTitle title="History">
        <PTable :column="column" :tableList="tableData" @getApi="queryApi" />
      </RowTitle>
    </div>
  </div>
</template>

<script>
import rowItemDes from '@/views/intern/pages/components/rowItemDes'
import RowTitle from '@/views/intern/pages/components/RowTitle'
import PTable from '@/views/intern/pages/components/PTable'
import mixinsGet from '@/views/intern/pages/mixinsGet'
import descriptions from '@/components/descriptions/descriptions'
import { getInternInfoTab } from '@/api/intern'
import { mergeDesDataOfPort } from '@/utils/util'
export default {
  name: 'Contract',
  mixins: [mixinsGet],
  components: {
    rowItemDes,
    RowTitle,
    PTable,
    descriptions
  },
  created() {
    this.queryApi()
  },
  methods: {
    queryApi() {
      this.loading = true
      let id = this.$route.query.id
      if (id) {
        getInternInfoTab({
          id,
          queryType: 'Contract'
        }).then((res) => {
          if (res && res.code === 200) {
            if (res.data) {
              mergeDesDataOfPort(this.listData, res.data)
              this.tableData = res.data.history
              setTimeout(() => {
                this.loading = false
              }, 500)
            }
          }
        })
      }
    }
  },
  data() {
    return {
      loading: false,
      listData: [
        {
          title: 'Current Position',
          column: 4,
          content: [
            {
              label: 'HFM Code',
              key: 'hfmCode',
              value: '',
              lg: 8
            },
            {
              label: 'Legal Entity',
              key: 'legalEntity',
              value: '',
              lg: 8
            },
            {
              label: 'Working Location',
              key: 'workingLocation',
              value: '',
              lg: 8
            },
            {
              label: 'Office Location',
              key: 'officeLocation',
              value: '',
              lg: 8
            },
            {
              label: 'Employee type',
              key: 'employeeType',
              value: '',
              lg: 8
            },
            {
              label: 'Contract End Date',
              key: 'contractEndDate',
              value: '',
              lg: 8
            },
            {
              label: 'Transfer to FTE',
              key: 'transferToFTE',
              value: 'No',
              lg: 8
            },
            {
              label: 'Onboarding Date for FTE',
              key: 'onboardingDateforFTE',
              value: '',
              lg: 8
            }
          ]
        }
      ],
      tableData: [],
      column: [
        {
          key: 'hfmCode',
          name: 'HFM Code',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 150
        },
        {
          key: 'legalEntity',
          name: 'Legal Entity',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'workLocation',
          name: 'Work Location',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'officeLocation',
          name: 'Office Location',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'employeeType',
          name: 'Employment type',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'contractEndDate',
          name: 'Contract end date',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120
        },
        {
          key: 'updateTime',
          name: 'Update Time',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'updateUser',
          name: 'Update User',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.Contract {
  height: 100%;
  &__content {
    height: 100%;
  }
}
</style>
