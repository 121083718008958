<template>
  <div class="Education" v-loading="loading">
    <div class="Education__content" v-show="!loading">
      <RowTitle title="Education">
        <div class="addButton">
          <el-button type="primary" @click="add">
            <div>添加</div>
          </el-button>
        </div>
        <PocTable
          hidepage
          :data="ruleForm.tableData"
          style="width: 100%"
          max-height="250"
        >
          <el-table-column width="100">
            <template #header>
              <div class="headerTitle">
                <div class="top">学历</div>
                <div class="bottom">Education</div>
              </div>
            </template>
            <template #default="scope">
              <div class="tableContent">
                {{ educationList[scope.row.education] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="140">
            <template #header>
              <div class="headerTitle">
                <div class="top">学校名称</div>
                <div class="bottom">Name of School</div>
              </div>
            </template>
            <template #default="scope">
              <div class="tableContent">
                {{ scope.row.nameOfSchool }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="140">
            <template #header>
              <div class="headerTitle">
                <div class="top">专业</div>
                <div class="bottom">Major Subject</div>
              </div>
            </template>
            <template #default="scope">
              <div class="tableContent">
                {{ scope.row.majorSubject }}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              <div class="headerTitle">
                <div class="top">起</div>
                <div class="bottom">From</div>
              </div>
            </template>
            <template #default="scope">
              <div class="tableContent">
                {{ scope.row.dateFrom }}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              <div class="headerTitle">
                <div class="top">迄</div>
                <div class="bottom">To</div>
              </div>
            </template>
            <template #default="scope">
              <div class="tableContent">
                {{ scope.row.dateTo }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template #header>
              <div class="headerTitle">
                <div class="top">修业状况</div>
                <div class="bottom">Degree Received</div>
              </div>
            </template>
            <template #default="scope">
              <div class="tableContent">
                {{ degreeReceivedList[scope.row.degreeReceived] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="240">
            <template #header>
              <div class="headerTitle">
                <div class="top">取得相应学位</div>
                <div class="bottom">Corresponding Degree Obtained</div>
              </div>
            </template>
            <template #default="scope">
              <div class="tableContent">
                {{ scope.row.correspondingDegreeObtained }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="100" fixed="right">
            <template #header>
              <div class="headerTitle">
                <div class="top">操作</div>
                <div class="bottom">Operation</div>
              </div>
            </template>
            <template #default="scope">
              <div class="tableContent">
                <svg-icon
                  icon="tableEdit"
                  title="编辑"
                  class="tablePointSvgClass"
                  @click.native="edit(scope.row, scope.$index)"
                ></svg-icon>
                <svg-icon
                  @click.native="deleteEducation(scope.row, scope.$index)"
                  class="tablePointSvgClass"
                  icon="delete"
                  title="删除"
                ></svg-icon>
              </div>
            </template>
          </el-table-column>
        </PocTable>
      </RowTitle>
      <EducationalRecordDialog
        ref="EducationalRecordDialogRef"
        @onConfirm="onConfirm"
      />
    </div>
  </div>
</template>

<script>
import PTable from '@/views/intern/pages/components/PTable'
import RowTitle from '@/views/intern/pages/components/RowTitle'
import EducationalRecordDialog from '@/views/intern/pages/dialog/EducationalRecordDialog'
import { deleteEducation, getInternInfoTab, saveEducation } from '@/api/intern'
export default {
  name: 'Education',
  data() {
    return {
      ruleForm: {
        tableData: []
      },
      degreeReceivedList: {
        'Certificate of Completion': '结业 Certificate of Completion',
        'Certificate of Incompletion': '肄业 Certificate of Completion',
        'Pursuing Study': '在读 Pursuing Study'
      },
      educationList: {
        PhD: '博士',
        Master: '硕士',
        Bachelor: '学士',
        Others: '其他'
      },
      loading: false,
      editIndex: '',
      userId: ''
    }
  },
  created() {
    this.queryApi()
  },
  methods: {
    edit(row, index) {
      this.editIndex = index
      this.$refs.EducationalRecordDialogRef.show({
        status: 'edit',
        content: row
      })
    },
    queryApi() {
      this.loading = true
      let id = this.$route.query.id
      this.userId = id
      if (id) {
        getInternInfoTab({
          id,
          queryType: 'Education'
        }).then((res) => {
          if (res && res.code === 200) {
            if (res.data) {
              this.ruleForm.tableData = res.data.educationList
              setTimeout(() => {
                this.loading = false
              }, 500)
            }
          }
        })
      }
    },
    add() {
      this.$refs.EducationalRecordDialogRef.show({
        status: 'add'
      })
    },
    onConfirm(val) {
      if (val.status === 'add') {
        // this.ruleForm.tableData.push({ ...val.content })
        const data = {
          ...val.content,
          id: '',
          userId: this.userId
        }
        saveEducation(data).then((res) => {
          if (res && res.code === 200) {
            this.queryApi()
            this.$refs.EducationalRecordDialogRef.close()
          }
        })
      } else {
        const data = {
          ...val.content,
          userId: this.userId
        }
        saveEducation(data).then((res) => {
          if (res && res.code === 200) {
            this.queryApi()
            this.$refs.EducationalRecordDialogRef.close()
          }
        })
        // this.ruleForm.tableData.splice(this.editIndex, 1, { ...val.content })
        // this.$refs.EducationalRecordDialogRef.close()
      }
    },
    deleteEducation(row, index) {
      if (Number(index) >= 0) {
        // this.ruleForm.tableData.splice(index, 1)
        deleteEducation({
          ids: row.id
        }).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('删除成功')
            this.queryApi()
          }
        })
      }
    }
  },
  components: {
    PTable,
    RowTitle,
    EducationalRecordDialog
  }
}
</script>

<style lang="scss" scoped>
.Education {
  height: 100%;
  &__content {
    height: 100%;
  }
  .headerTitle {
    text-align: center;
  }
  .addButton {
    float: right;
    margin-bottom: 12px;
  }
}
</style>
