<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="close"
  >
    <el-form
      v-loading="loading"
      element-loading-text="请稍后.."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
      :model="formInline"
      class="FinanceInfo-form"
      label-position="top"
    >
      <el-row>
        <el-col :lg="12">
          <el-form-item label="Brand">
            <el-input v-model="formInline.brand" />
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="Business Unit">
            <el-input v-model="formInline.businessUnitId" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12">
          <el-form-item label="Job Grade">
            <el-input v-model="formInline.jobGrade" />
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="Parent Function">
            <el-select
              v-model="formInline.parentFunction"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in parentFunctionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12">
          <el-form-item label="Child Function">
            <el-select
              v-model="formInline.childFunction"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in childFunctionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="Lion ID of Line Manager">
            <el-input v-model="formInline.lionIdOfLineManager" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12">
          <el-form-item label="Update Time">
            <el-date-picker
              v-model="formInline.updateTime"
              type="date"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="Update User">
            <el-date-picker
              v-model="formInline.updateUser"
              type="date"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'positionDialog',
  data() {
    return {
      title: 'title',
      loading: true,
      dialogVisible: false,
      formInline: {
        brand: '',
        businessUnitId: '',
        jobGrade: '',
        parentFunction: '',
        childFunction: '',
        lionIdOfLineManager: '',
        updateTime: '',
        updateUser: ''
      },
      childFunctionList: [],
      parentFunctionList: []
    }
  },
  methods: {
    async show(val) {
      this.dialogVisible = true

      await this.queryApi()
      if (val) {
        if (val.content) {
          Object.keys(this.formInline).forEach((item) => {
            this.formInline[item] = val.content[item]
          })
        }
      }
      this.loading = false
    },
    save() {
      this.$emit('onConfirm', this.formInline)
      this.close()
    },
    close() {
      this.dialogVisible = false
    },
    async queryApi() {
      // let hfmCodeList = await getHfmCodeList()
      // if (hfmCodeList && hfmCodeList.code === 200) {
      //   if (hfmCodeList.data) {
      //     this.HFMList = hfmCodeList.data
      //   }
      // }
    }
  }
}
</script>

<style lang="scss" scoped></style>
