<template>
  <div class="RowTitle">
    <div class="RowTitle-title">{{ title }}</div>
    <div class="RowTitle-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RowTitle',
  props: {
    title: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.RowTitle {
  .RowTitle-title {
    font-size: 16px;
    font-family: ArialMT;
    color: #303133;
  }
  .RowTitle-content {
    padding: 10px 0 20px 0;
  }
}
</style>
