<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="close"
  >
    <el-form
      v-loading="loading"
      element-loading-text="请稍后.."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
      :model="formInline"
      class="FinanceInfo-form"
      label-position="top"
    >
      <el-row>
        <el-col :lg="12">
          <el-form-item label="HFM Code">
            <el-select
              v-model="formInline.hfmCode"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in HFMList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="Legal Entity">
            <el-select
              v-model="formInline.legalEntity"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in legalEntityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12">
          <el-form-item label="Work Location">
            <el-select
              v-model="formInline.workLocation"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in workLocationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="Employment type">
            <el-input v-model="formInline.employmentType" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12">
          <el-form-item label="Office Location">
            <el-select
              v-model="formInline.officeLocation"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in officeLocationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="Contract end date">
            <el-input v-model="formInline.contractEndDate" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12">
          <el-form-item label="Update User">
            <el-date-picker
              v-model="formInline.updateUser"
              type="date"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getHfmCodeList } from '@/api/organization'

export default {
  name: 'baseInfoDialog',
  data() {
    return {
      title: 'title',
      loading: true,
      dialogVisible: false,
      formInline: {
        hfmCode: '',
        legalEntity: '',
        workLocation: '',
        officeLocation: '',
        employmentType: '',
        contractEndDate: '',
        updateUser: ''
      },
      HFMList: [],
      legalEntityList: [],
      workLocationList: [],
      officeLocationList: []
    }
  },
  methods: {
    async show(val) {
      this.dialogVisible = true

      await this.queryApi()
      if (val) {
        if (val.content) {
          Object.keys(this.formInline).forEach((item) => {
            this.formInline[item] = val.content[item]
          })
        }
      }
      this.loading = false
    },
    save() {
      this.$emit('onConfirm', this.formInline)
      this.close()
    },
    close() {
      this.dialogVisible = false
    },
    async queryApi() {
      let hfmCodeList = await getHfmCodeList()
      if (hfmCodeList && hfmCodeList.code === 200) {
        if (hfmCodeList.data) {
          this.HFMList = hfmCodeList.data
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
